import React from 'react'
import styles from './DescriptionComplex.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import image from '../../image/DescriptionComplex/img.png'
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"

const DescriptionComplex = () => {

    return (
        <section className={styles.main} id='proekt'>
            <div className={styles.box}>
                <div className={styles.slider}>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
            >
                <SwiperSlide>
                <div className={styles.item}>
                    <div className={styles.box_text}>
                        <h3  className={styles.item_title}>Жилой квартал City&#160;Park</h3>
                        <p className={styles.item_text}>City Park — новый жилой комплекс в центре Москвы рядом с парком «Красная Пресня». Террасная застройка шести корпусов City Park, эксплуатируемые крыши, внутренние дворы, организованные по принципу частного сада, предоставляют жителям квартир City Park возможность быть ближе к природе, оставаясь в центре города. Использование натуральных материалов и воссоздание эргономичных форм делают новостройки ЖК City Park островом комфортной и качественной городской жизни в центре Москвы.</p>
                    </div>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={image} alt='мальчик'/>
                            <div className={styles.image_info}>
                                <h3 className={styles.image_title}>Виктория Уланова</h3>
                                <p className={styles.image_text}>эксперт проекта</p>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                <div className={styles.item}>
                    <div className={styles.box_text}>
                        <h3  className={styles.item_title}>Расположение City&#160;Park</h3>
                        <p className={styles.item_text}>Некогда индустриальный центр сегодня является одним из самых динамичных городских районов для представителей бизнеса, политической власти и креативного класса. Конструктивистские кварталы Нижней Пресни, небоскребы «Москва-Сити», «Афимолл», Москва-река, парк «Красная Пресня» и перспективный городской кластер «Трехгорная мануфактура» — это контрастное окружение и есть ритм современной Москвы. Инфраструктура места позволит комфортно себя чувствовать людям любого возраста.</p>
                    </div>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={image} alt='мальчик'/>
                            <div className={styles.image_info}>
                                <h3 className={styles.image_title}>Виктория Уланова</h3>
                                <p className={styles.image_text}>эксперт проекта</p>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
                <SwiperSlide>
                <div className={styles.item}>
                    <div className={styles.box_text}>
                        <h3  className={styles.item_title}>Комфорт City&#160;Park</h3>
                        <p className={styles.item_text}>Интеграция архитектуры в природный ландшафт, использование натуральных материалов и воссоздание эргономичных форм делают ЖК премиум класса City Park островом комфортной и качественной городской жизни в Москве. Это уникальный городской квартал премиум-класса, где парковая зелень соседствует с небоскребами Москва-Сити, где находится ваш уютный и современный дом с садом. Собственность в City Park — это инвестиции в свое комфортное будущее.</p>
                    </div>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={image} alt='мальчик'/>
                            <div className={styles.image_info}>
                                <h3 className={styles.image_title}>Виктория Уланова</h3>
                                <p className={styles.image_text}>эксперт проекта</p>
                            </div>
                        </div>
                    </div>  
                </SwiperSlide>
            </Swiper>  
                </div>
                
            </div>
        </section>
    )
}

export default DescriptionComplex

/*<div class="expert-slider__item-desc" data-name="slides][0][description">
            City Park&nbsp;— жилой квартал премиум-класса, расположенный в&nbsp;Краснопресненском районе в&nbsp;десяти минутах от&nbsp;Садового кольца и&nbsp;от&nbsp;станции метро «Выставочная». <br>
 <br>
 В&nbsp;шести корпусах переменной этажности для покупателей доступны 1220 квартир, площадью от&nbsp;33&nbsp;до&nbsp;250&nbsp;м², из&nbsp;панорамных окон которых открываются восхитительные виды на&nbsp;Москва-реку, парк «Красная Пресня» и&nbsp;башни «Москва-Сити».
        </div></br>*/

