import React from 'react'
import styles from './MenuMobile.module.scss'
import logo from '../../image/Header/logo.png'

const MenuMobile = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <a className={styles.link} href='#galereya' onClick={onClose}>Галерея</a>
                    <a className={styles.link} href='#proekt' onClick={onClose}>О проекте</a>
                    <a className={styles.link} href='#planirovki' onClick={onClose}>Планировки</a>
                    <a className={styles.link} href='#interiery' onClick={onClose}>Интерьеры</a>
                    <a className={styles.link} href='#okruzhenie' onClick={onClose}>Окружение</a>
                    <a className={styles.link} href='#raspolozhenie' onClick={onClose}>Расположение</a>
                    <a className={styles.link} href='#kontakty' onClick={onClose}>Контакты</a>
            </nav>
        </div>
    );
}

export default MenuMobile
