import React from 'react'
import { createPortal } from "react-dom"
import modal from './ModalPump.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'

const ModalPump = ({onClose}) => {

    const modalRoot = document.getElementById("modals");

    return createPortal (
        <ModalOverlay>
            <div className={modal.modal} onClick={evt => evt.stopPropagation()}>
                <div className={modal.overlay}>
                    <h2 className={modal.title}>Спасибо за обращение</h2>
                    <p className={modal.text}>через несколько секунд каталог уже будет у вас</p>
                    <button className={modal.button} type='button' onClick={onClose}>Вернуться на сайт</button>
                </div>    
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};

export default ModalPump