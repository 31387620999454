import React from 'react'
import styles from './ObjectsNearby.module.scss'
import { motion } from "framer-motion"
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import { arrObjects } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import "./Loading.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"


const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const ObjectsNearby = () => {

    return (
        <motion.section 
            className={styles.main}
            id='okruzhenie'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Интересные места поблизости</motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>В ближайшей доступности от жилого комплекса City Park располагаются гимназии, лицеи, ведущие университеты страны, парки и стадионы, что формирует инфраструктуру особого уровня.</motion.p>
                <motion.div className={styles.slider} variants={boxAnimation}>
                        <Swiper
                                spaceBetween={15}
                                slidesPerView={3.5}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 15,
                                    },

                                    768: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 10,
                                    },

                                    425: {
                                        slidesPerView: 1.7,
                                        spaceBetween: 10,
                                    },

                                    320: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            {arrObjects.map((item, index) =>{
                                const keyUid = uuidv4()
                                if (index + 1 <= arrObjects.length) {
                                    
                                    return(
                                        <SwiperSlide key={keyUid}>
                                            <div className={styles.item}>
                                                <img className={styles.image}  src={item.image} alt={item.title}/>
                                                <div className={styles.item_info}>
                                                    <h3 className={styles.item_title} >{item.title}</h3>
                                                    <p className={styles.item_text}>{item.text}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ) 
                                } else {
                                    return ''
                                }
                            })}       
                        </Swiper>
                    </motion.div>
                
            </div>
        </motion.section>
    )
}

export default ObjectsNearby