import React, {useState} from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.png'

const Header = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    }

    window.addEventListener('scroll', toggleVisible);

    return (
        <header className={`${header.header} ${visible && header.header_scroll}`}>
        <div className={header.box}>
                <div className={header.info}>
                    <div className={header.box_logo}>
                        <a className={header.logo_link} href='initial'>
                            <img className={header.logo} src={logo} alt = 'логотип'/>
                        </a>
                        <div className={header.contacts}>
                            <ul className={header.list}>
                                <li className={header.item_phone}>
                                    <a className={header.link_telegram} target='_blank' rel='noopener noreferrer' href='tel:+74955323256' aria-label='телефон'>
                                    <svg  className={header.image_icon} fill="#FFF" viewBox="0 0 16.933333 16.933334" ><g id="layer1" transform="translate(0 -280.067)"><path id="path40973" d="m3.1636285 280.84799c-.2645304.008-.4969192.1476-.6914303.3421l-.3245273.32401 3.5170983 3.58117.5105612-.51056c.3076998-.30769.3076919-.81575 0-1.12345l-2.271694-2.27117c-.2200302-.22004-.4754881-.35023-.7400079-.3421zm-1.3911289 1.04024-.3725863.37208c-.60967143.60966-.60564976 1.40738-.60564976 1.7756 0 1.52362.60912106 4.15774 4.31033246 7.85895 3.7012192 3.70122 6.33584 4.31085 7.859469 4.31085.36825 0 1.165953.004 1.775601-.60565l.336931-.33693-3.517099-3.58118-.368969.36949c-.21866.21865-.380016.24479-.536919.22634-.15689-.0184-.319088-.10919-.437698-.20102-.6467791-.50074-1.6939576-1.21749-2.88458-2.40812-1.1906277-1.19063-1.9073813-2.23832-2.4081211-2.8851-.0918-.11861-.1825705-.27873-.2010199-.43563-.018494-.1569.0099-.32032.2284095-.53899l.3395134-.33951zm11.2039664 8.70542c-.203808 0-.40736.0771-.561205.23099l-.481623.48214 3.517614 3.58169.358635-.35863c.194498-.19449.333449-.42897.341579-.6935.008-.26452-.121549-.51997-.341579-.74001l-2.271697-2.27169c-.15385-.15385-.357915-.23099-.561724-.23099z" font-variant-ligatures="normal" font-variant-position="normal" font-variant-caps="normal" font-variant-numeric="normal" font-variant-alternates="normal" font-feature-settings="normal" text-indent="0" text-align="start" text-decoration-line="none" text-decoration-style="solid" text-decoration-color="rgb(0,0,0)" text-transform="none" text-orientation="mixed" white-space="normal" shape-padding="0" isolation="auto" mix-blend-mode="normal" solid-color="rgb(0,0,0)" solid-opacity="1" vector-effect="none"/></g></svg>
                                    </a>
                                </li>
                                <li className={header.item}>
                                    <a className={header.link_whatsapp} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79932507445' aria-label='связаться вацап'>
                                    <svg  className={header.image_icon} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
                                    </a>
                                </li>
                                <li className={header.item}>
                                    <a className={header.link_telegram} target='_blank' rel='noopener noreferrer' href='https://t.me/+79932507445' aria-label='телеграмм'>
                                    <svg className={header.image_icon} aria-hidden="true" focusable="false" data-prefix="fab" data-icon="telegram-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
                                    </a>
                                </li>
                            </ul>
                            <div className={header.box_phone}>
                                <a className={header.phone}  href="tel:+74955323256" target='_blank' rel='noopener noreferrer'>+7 495 <span>5323256</span></a>
                            </div>
                        </div>
                        <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
                    </div>
                    <nav className={header.nav}>
                        <a className={header.link} href='#galereya'>Галерея</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#proekt'>О проекте</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#planirovki'>Планировки</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#interiery'>Интерьеры</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#okruzhenie'>Окружение</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#raspolozhenie'>Расположение</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#kontakty'>Контакты</a>
                    </nav>
                </div>
        </div>
    </header>    
    );
};

export default Header