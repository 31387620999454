import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.p className={styles.text} variants={titleAnimation}>ЖИЛОЙ КОМПЛЕКС</motion.p>
                            <motion.h1 className={styles.title} variants={titleAnimation}>City Park</motion.h1>
                            <motion.p className={styles.subtitle} variants={subtitleAnimation}>Жилой квартал премиум-класса, расположенный в Краснопресненском районе в десяти минутах от Садового кольца и от станции метро «Выставочная»</motion.p>
                            <motion.p className={styles.subtitle} variants={subtitleAnimation}>Квартиры, площадью от 33 до 250 м², из панорамных окон которых открываются восхитительные виды на Москва-реку, парк «Красная Пресня» и башни «Москва-Сити».</motion.p>
                            <div className={styles.box_button}>
                                <a className={styles.button} href='#raspolozhenie'> Мантулинская, 9</a>
                            </div>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}

export default InitialPage