import React, {useState, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './ProjectGallery.module.scss'
import img_1 from '../../image/ProjectGallery/img_1.webp'
import img_2 from '../../image/ProjectGallery/img_2.webp'
import img_3 from '../../image/ProjectGallery/img_3.webp'
import img_4 from '../../image/ProjectGallery/img_4.webp'
import img_5 from '../../image/ProjectGallery/img_5.webp'


import img_1_1 from '../../image/ProjectGallery/Lobby/img_1.webp'
import img_2_1 from '../../image/ProjectGallery/Lobby/img_2.webp'
import img_3_1 from '../../image/ProjectGallery/Lobby/img_3.webp'
import img_4_1 from '../../image/ProjectGallery/Lobby/img_4.webp'
import img_5_1 from '../../image/ProjectGallery/Lobby/img_5.webp'

import img_1_2 from '../../image/ProjectGallery/Interiors/img_1.webp'
import img_2_2 from '../../image/ProjectGallery/Interiors/img_2.webp'
import img_3_2 from '../../image/ProjectGallery/Interiors/img_3.webp'
import img_4_2 from '../../image/ProjectGallery/Interiors/img_4.webp'
import img_5_2 from '../../image/ProjectGallery/Interiors/img_5.webp'

import img_1_3 from '../../image/ProjectGallery/Kinds/img_1.webp'
import img_2_3 from '../../image/ProjectGallery/Kinds/img_2.webp'
import img_3_3 from '../../image/ProjectGallery/Kinds/img_3.webp'
import img_4_3 from '../../image/ProjectGallery/Kinds/img_4.webp'
import img_5_3 from '../../image/ProjectGallery/Kinds/img_5.webp'


const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}


const ProjectGallery = ({isImageOpen}) => {

    const [isImage, setIsImege] = useState(1)
    const [isBackground, setIsBackground] = useState(1)

    const handleBackgroundOne = useCallback(() => {
        setIsBackground(1)
        setIsImege(1)
    }, []);

    const handleBackgroundTwo = useCallback(() => {
        setIsBackground(2)
        setIsImege(2)
    }, []);

    const handleBackgroundThree = useCallback(() => {
        setIsBackground(3)
        setIsImege(3)
    }, []);

    const handleBackgroundFour = useCallback(() => {
        setIsBackground(4)
        setIsImege(4)
    }, []);


    return (
        <motion.section 
            className={styles.main}
            id='galereya'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Галерея проекта</motion.h2>
                <motion.ul className={styles.list_link} variants={titleAnimation}>
                        <li className={`${isBackground === 1 ? styles.item_blue : styles.item}`} onClick={handleBackgroundOne}>архитектура
                        </li>
                        <li className={`${isBackground === 2 ? styles.item_blue : styles.item}`} onClick={handleBackgroundTwo}>лобби</li>
                        <li className={`${isBackground === 3 ? styles.item_blue : styles.item}`} onClick={handleBackgroundThree}>территория</li>
                        <li className={`${isBackground === 4 ? styles.item_blue : styles.item}`} onClick={handleBackgroundFour}>виды</li>
                </motion.ul>
                <motion.ul className={`${isImage === 1 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                    <li className={styles.item_1}>
                        <img className={styles.image} src={img_1} alt = 'здание'/>
                    </li>
                    <li className={styles.item_2}>
                        <img className={styles.image} src={img_2} alt = 'здание'/>
                    </li>
                    <li className={styles.item_3}>
                        <img className={styles.image} src={img_3} alt = 'здание'/>
                    </li>
                    <li className={styles.item_4}>
                        <img className={styles.image} src={img_4} alt = 'здание'/>
                    </li>
                    <li className={styles.item_5}>
                        <img className={styles.image} src={img_5} alt = 'здание'/>
                    </li>
                </motion.ul>
                <motion.ul className={`${isImage === 2 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                    <li className={styles.item_1}>
                        <img className={styles.image} src={img_1_1} alt = 'здание'/>
                    </li>
                    <li className={styles.item_2}>
                        <img className={styles.image} src={img_2_1} alt = 'здание'/>
                    </li>
                    <li className={styles.item_3}>
                        <img className={styles.image} src={img_3_1} alt = 'здание'/>
                    </li>
                    <li className={styles.item_4}>
                        <img className={styles.image} src={img_4_1} alt = 'здание'/>
                    </li>
                    <li className={styles.item_5}>
                        <img className={styles.image} src={img_5_1} alt = 'здание'/>
                    </li>
                </motion.ul>
                <motion.ul className={`${isImage === 3 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                    <li className={styles.item_1}>
                        <img className={styles.image} src={img_1_2} alt = 'здание'/>
                    </li>
                    <li className={styles.item_2}>
                        <img className={styles.image} src={img_2_2} alt = 'здание'/>
                    </li>
                    <li className={styles.item_3}>
                        <img className={styles.image} src={img_3_2} alt = 'здание'/>
                    </li>
                    <li className={styles.item_4}>
                        <img className={styles.image} src={img_4_2} alt = 'здание'/>
                    </li>
                    <li className={styles.item_5}>
                        <img className={styles.image} src={img_5_2} alt = 'здание'/>
                    </li>
                </motion.ul>
                <motion.ul 
                    className={`${isImage === 4 ? styles.list_image : styles.list_image_none}`}
                    variants={boxAnimation}>
                    <li className={styles.item_1}>
                        <img className={styles.image} src={img_1_3} alt = 'здание'/>
                    </li>
                    <li className={styles.item_2}>
                        <img className={styles.image} src={img_2_3} alt = 'здание'/>
                    </li>
                    <li className={styles.item_3}>
                        <img className={styles.image} src={img_3_3} alt = 'здание'/>
                    </li>
                    <li className={styles.item_4}>
                        <img className={styles.image} src={img_4_3} alt = 'здание'/>
                    </li>
                    <li className={styles.item_5}>
                        <img className={styles.image} src={img_5_3} alt = 'здание'/>
                    </li>
                </motion.ul>
                <div className={styles.box_butoon}>
                    <button className={styles.butoon} onClick={isImageOpen}>Cмотреть все фото</button>
                </div>
            </div>
        </motion.section>
    )
}

export default ProjectGallery