import React from 'react'
import { motion } from "framer-motion"
import styles from './InteriorDecoration.module.scss'
import img_1 from '../../image/InteriorDecoration/Bedroom/img_1.webp'
import img_2 from '../../image/InteriorDecoration/LivingRoom/img_1.webp'
import img_3 from '../../image/InteriorDecoration/Kitchen/img_1.webp'
import img_4 from '../../image/InteriorDecoration/Bathroom/img_1.webp'

const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const InteriorDecoration = ({isImageOpen}) => {


    return (
        <motion.section 
            className={styles.main}
            id='interiery'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Комплекс стильных решений</motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>Часть квартир в City Park продаются с отделкой. Такое предложение идеально подходит для тех, кто предпочитает быстрые и комфортные решения, и позволяет сэкономить время, нервы и деньги.</motion.p>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item} onClick={isImageOpen}>
                        <img className={styles.item_image}  src={img_1} alt='Спальня'/>
                        <div className={styles.item_info}>
                            <h3 className={styles.item_title} >Спальня</h3>
                        </div>
                    </li>
                    <li className={styles.item} onClick={isImageOpen}>
                        <img className={styles.item_image}  src={img_2} alt='Спальня'/>
                        <div className={styles.item_info}>
                            <h3 className={styles.item_title} >Гостинная</h3>
                        </div>
                    </li>
                    <li className={styles.item} onClick={isImageOpen}>
                        <img className={styles.item_image}  src={img_3} alt='Спальня'/>
                        <div className={styles.item_info}>
                            <h3 className={styles.item_title} >Кухня</h3>
                        </div>
                    </li>
                    <li className={styles.item} onClick={isImageOpen}>
                        <img className={styles.item_image}  src={img_4} alt='Спальня'/>
                        <div className={styles.item_info}>
                            <h3 className={styles.item_title} >Ванная</h3>
                        </div>
                    </li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default InteriorDecoration