import React, {useEffect} from 'react'
import styles from './ModalTime.module.scss'
import { ModalOverlay } from '../ModalOverlay/ModalOverlay'
import image from '../../image/PresentationСomplex/img.webp'
import image_1 from '../../image/PresentationСomplex/img_2.webp'
import wp from '../../image/PresentationСomplex/wp.svg'
import tg from '../../image/PresentationСomplex/tg.svg' 

const ModalTime = ({onClose, popupOpen}) => {

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return (
        <ModalOverlay onClick={onClose}>
            <section className={styles.main}>
            <div className={styles.box}>
            <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={onClose}/> 
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Презентация комплекса</h2>
                    <p className={styles.text}>Расположение корпусов, архитектура, планировки и цены на квартиры в жилых небоскрёбах в нашей PDF-презентации.</p>
                    <button className={styles.button} type='button' onClick={popupOpen}>Скачать на сайте</button>
                    <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79932507445' aria-label='связаться вацап'>
                                <img src={wp} alt="Whatsapp"/>
                                <span>Получить в Whatsapp</span>
                            </a>
                            <a className={styles.link_telegram} target='_blank' rel='noopener noreferrer' href='https://t.me/Dmitry_Tihonoff' aria-label='телеграмм'>
                                <img src={tg} alt="Whatsapp"/>
                                <span>Получить в telegram</span>
                            </a>
                </div>
                <img className={styles.image} src={image} alt='презентация'/>
                <img className={styles.image_min} src={image_1} alt='презентация'/>
            </div>
        </section>
        </ModalOverlay>
    );
};

export default ModalTime