import React, {useCallback, useState, useEffect} from 'react'
import app from './App.module.scss'
import Main from '../../pages/Main/Main'
import Header  from '../Header/Header'
import Footer  from '../Footer/Footer'
import MenuMobile  from '../MenuMobile/MenuMobile'
import { ModalForm } from '../ModalForm/ModalForm'
import { Modal } from '../Modal/Modal'
import { ModalImage } from '../ModalImage/ModalImage'
import UpButton from '../UpButton/UpButton'
import { ModalInteriors } from '../ModalInteriors/ModalInteriors'
import ButtonCommunication  from '../ButtonCommunication/ButtonCommunication'
import ModalPump from '../ModalPump/ModalPump'
import ModalPresentation from '../ModalPresentation/ModalPresentation'
import ModalTime from '../ModalTime/ModalTime'

function App() {

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isIndex, setIsIndex] = useState(null)
  const [isArr, setIsArr] = useState(null)
  const [isInteriors, setIsInteriors] = useState(null)
  const [isPumpOpen, setIsPumpOpen] = useState(null)
  const [isPresentation, setIsPresentation] = useState(null)
  const [isModalTime, setIsModalTime] = useState(false)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  useEffect(() => {

    setTimeout(function() {
      setIsModalTime(true);
    }, 30000);
  }, []);


  const handleModalTimeClose = useCallback(() => {
    setIsModalTime(false)
  }, []);

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handlePresentationOpen = useCallback(() => {
    setIsPresentation(true)
  }, []);

  const handlePresentationClose = useCallback(() => {
    setIsPresentation(false)
  }, []);

  const handlePumpOpen = useCallback(() => {
    setIsPumpOpen(true)
  }, []);

  const handlePumpClose = useCallback(() => {
    setIsPumpOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);  

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((index, arr) => {
      setIsIndex(index)
      setIsArr(arr)
      setIsPopupImageOpen(true)
  }, [])

  const handleInteriorsClose = useCallback(() => {
    setIsInteriors(false)
  }, []);


  const handleInteriorsOpen = useCallback((index, arr) => {
      setIsIndex(index)
      setIsArr(arr)
      setIsInteriors(true)
  }, [])


  return (
    <div className={app.page}>
      <Header
        isOpen={handleOpenMenuMobile}
      />
      <Main
        isOpen={handleFormOpen}
        popupOpen={handleOpen}
        isImageOpen={handleImageOpen}
        isInteriorsOpen={handleInteriorsOpen}
        isPresentationOpen={handlePresentationOpen}
      />
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && 
      <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPresentation && 
      <ModalPresentation
        isPresentationClose={handlePresentationClose}
        popupOpen={handlePumpOpen}
      />}
      {isPumpOpen && 
      <ModalPump
        onClose={handlePumpClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        index={isIndex}
        arr={isArr}
      />}
      {isInteriors && 
      <ModalInteriors
        onClose={handleInteriorsClose}
      />}
      {isModalTime && 
      <ModalTime
        onClose={handleModalTimeClose}
        popupOpen={handlePresentationOpen}
      />}
      <Footer/>
      <UpButton/>
      <ButtonCommunication
          isOpen={handleFormOpen}
      />
    </div>
  );
}

export default App;
