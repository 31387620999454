import React from 'react'
import { motion } from "framer-motion"
import styles from './PhoneCoffee.module.scss'
import image from '../../image/PhoneCoffee/bg-commerce.png'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const PhoneCoffee = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Хотите&#160;купить коммерческое помещение в&#160;ЖК&#160;City Park?</h2>
                    <p className={styles.text}>Оставьте заявку, и эксперт проекта свяжется с вами и подберёт подходящий вариант</p>
                    <button className={styles.button} type='button' onClick={isOpen}>Оставьте заявку</button>
                </div>
                <img className={styles.image} src={image} alt='кофе'/>
            </motion.div>
        </motion.section>
    )
}

export default PhoneCoffee