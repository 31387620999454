import oil_1 from '../image/Loading/img_1.webp'
import oil_2 from '../image/Loading/img_2.webp'
import oil_3 from '../image/Loading/img_3.webp'
import oil_4 from '../image/Loading/img_4.webp'
import oil_5 from '../image/Loading/img_5.webp'
import oil_6 from '../image/Loading/img_6.webp'
import oil_7 from '../image/Loading/img_7.webp'
import oil_8 from '../image/Loading/img_8.webp'
import oil_9 from '../image/Loading/img_9.webp'

import dev_1 from '../image/Developer/img_1.webp'
import dev_2 from '../image/Developer/img_2.webp'
import dev_3 from '../image/Developer/img_3.webp'
import dev_4 from '../image/Developer/img_4.webp'
import dev_5 from '../image/Developer/img_5.webp'
import dev_6 from '../image/Developer/img_6.webp'

import img_1 from '../image/ProjectGallery/img_1.webp'
import img_2 from '../image/ProjectGallery/img_2.webp'
import img_3 from '../image/ProjectGallery/img_3.webp'
import img_4 from '../image/ProjectGallery/img_4.webp'
import img_5 from '../image/ProjectGallery/img_5.webp'


import img_1_1 from '../image/ProjectGallery/Lobby/img_1.webp'
import img_2_1 from '../image/ProjectGallery/Lobby/img_2.webp'
import img_3_1 from '../image/ProjectGallery/Lobby/img_3.webp'
import img_4_1 from '../image/ProjectGallery/Lobby/img_4.webp'
import img_5_1 from '../image/ProjectGallery/Lobby/img_5.webp'

import img_1_2 from '../image/ProjectGallery/Interiors/img_1.webp'
import img_2_2 from '../image/ProjectGallery/Interiors/img_2.webp'
import img_3_2 from '../image/ProjectGallery/Interiors/img_3.webp'
import img_4_2 from '../image/ProjectGallery/Interiors/img_4.webp'
import img_5_2 from '../image/ProjectGallery/Interiors/img_5.webp'

import img_1_3 from '../image/ProjectGallery/Kinds/img_1.webp'
import img_2_3 from '../image/ProjectGallery/Kinds/img_2.webp'
import img_3_3 from '../image/ProjectGallery/Kinds/img_3.webp'
import img_4_3 from '../image/ProjectGallery/Kinds/img_4.webp'
import img_5_3 from '../image/ProjectGallery/Kinds/img_5.webp'

import int_1 from '../image/InteriorDecoration/Bedroom/img_1.webp'
import int_2 from '../image/InteriorDecoration/Bedroom/img_2.webp'
import int_3 from '../image/InteriorDecoration/Bedroom/img_3.webp'
import int_4 from '../image/InteriorDecoration/LivingRoom/img_1.webp'
import int_5 from '../image/InteriorDecoration/LivingRoom/img_2.webp'
import int_6 from '../image/InteriorDecoration/LivingRoom/img_3.webp'
import int_7 from '../image/InteriorDecoration/Kitchen/img_1.webp'
import int_8 from '../image/InteriorDecoration/Kitchen/img_2.webp'
import int_9 from '../image/InteriorDecoration/Kitchen/img_3.webp'
import int_10 from '../image/InteriorDecoration/Bathroom/img_1.webp'
import int_11 from '../image/InteriorDecoration/Bathroom/img_2.webp'
import int_12 from '../image/InteriorDecoration/Bathroom/img_1.webp'

import pla_40 from '../image/Planning/40.webp'
import pla_42 from '../image/Planning/42.webp'
import pla_41 from '../image/Planning/41.webp'
import pla_43 from '../image/Planning/43.webp'
import pla_52 from '../image/Planning/52.webp'
import pla_59 from '../image/Planning/59.webp'
import pla_65 from '../image/Planning/65.webp'
import pla_65_1 from '../image/Planning/65_1.webp'
import pla_80 from '../image/Planning/80.webp'
import pla_83 from '../image/Planning/83.webp'
import pla_87 from '../image/Planning/87.webp'
import pla_87_1 from '../image/Planning/87_1.webp'
import pla_88 from '../image/Planning/88.webp'
import pla_103 from '../image/Planning/103.webp'
import pla_118 from '../image/Planning/118.webp'
import pla_127 from '../image/Planning/127.webp'
import pla_129 from '../image/Planning/129.webp'
import pla_132_1 from '../image/Planning/132_1.webp'
import pla_132 from '../image/Planning/132.webp'
import pla_134 from '../image/Planning/134.webp'
import pla_135 from '../image/Planning/135.webp'
import pla_172 from '../image/Planning/172.webp'

export const arrInteriors = [{image: int_1, title: 'ЖК City Park интерьеры'}, {image: int_2, title: 'ЖК City Park интерьеры'}, {image: int_3, title: 'ЖК City Park интерьеры'}, {image: int_4, title: 'ЖК City Park интерьеры'}, {image: int_5, title: 'ЖК City Park интерьеры'}, {image: int_6, title: 'ЖК City Park интерьеры'}, {image: int_7, title: 'ЖК City Park интерьеры'}, {image: int_8, title: 'ЖК City Park интерьеры'}, {image: int_9, title: 'ЖК City Park интерьеры'}, {image: int_10, title: 'ЖК City Park интерьеры'}, {image: int_11, title: 'ЖК City Park интерьеры'}, {image: int_12, title: 'ЖК City Park интерьеры'},];

export const arrOneRoom = [{img: pla_40, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '40',}, {img: pla_41, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '41',}, {img: pla_42, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '42',}, {img: pla_43, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '43',}, {img: pla_52, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '52',},  {img: pla_59, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '59',}, {img: pla_65, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '65',},  {img: pla_65_1, apartment: '1 спальня', price: '23 000 000', status: 'Без отделки', size: '65',},];

export const arrTwoRooms = [{img: pla_80, apartment: '2 спальни', price: '30 800 000', status: 'Без отделки', size: '80',}, {img: pla_83, apartment: '2 спальни', price: '30 800 000', status: 'Без отделки', size: '83',}, {img: pla_87, apartment: '2 спальни', price: '30 800 000', status: 'Без отделки', size: '87',}, {img: pla_87_1, apartment: '2 спальни', price: '30 800 000', status: 'Без отделки', size: '87',}, {img: pla_88, apartment: '2 спальни', price: '30 800 000', status: 'Без отделки', size: '88',},];

export const arrThreeRooms = [{img: pla_103, apartment: '3 спальни', price: '41 000 000', status: 'Без отделки', size: '103',}, {img: pla_118, apartment: '3 спальни', price: '41 000 000', status: 'Без отделки', size: '118',}, {img: pla_127, apartment: '3 спальни', price: '41 000 000', status: 'Без отделки', size: '127',}, {img: pla_132_1, apartment: '3 спальни', price: '41 000 000', status: 'Без отделки', size: '132',}, {img: pla_134, apartment: '3 спальни', price: '41 000 000', status: 'Без отделки', size: '134',},];

export const arrFourRooms = [{ img: pla_129, apartment: '4 спальни', price: '61 000 000', status: 'Без отделки', size: '129',}, { img: pla_132, apartment: '4 спальни', price: '61 000 000', status: 'Без отделки', size: '132',}, { img: pla_135, apartment: '4 спальни', price: '61 000 000', status: 'Без отделки', size: '135',}, { img: pla_172, apartment: '4 спальни', price: '61 000 000', status: 'Без отделки', size: '172',},];

export const arrArchitecture = [{image: img_1, title: 'ЖК City Park архитектура'}, {image: img_2, title: 'ЖК City Park архитектура'}, {image: img_3, title: 'ЖК City Park архитектура'}, {image: img_4, title: 'ЖК City Park архитектура'}, {image: img_5, title: 'ЖК City Park архитектура'}, {image: img_1_1, title: 'ЖК City Park архитектура'}, {image: img_2_1, title: 'ЖК City Park архитектура'}, {image: img_3_1, title: 'ЖК City Park архитектура'}, {image: img_4_1, title: 'ЖК City Park архитектура'}, {image: img_5_1, title: 'ЖК City Park архитектура'}, {image: img_1_2, title: 'ЖК Небо архитектура'}, {image: img_2_2, title: 'ЖК Небо архитектура'}, {image: img_3_2, title: 'ЖК City Park архитектура'}, {image: img_4_2, title: 'ЖК City Park архитектура'}, {image: img_5_2, title: 'ЖК City Park архитектура'}, {image: img_1_3, title: 'ЖК City Park архитектура'}, {image: img_2_3, title: 'ЖК City Park архитектура'}, {image: img_3_3, title: 'ЖК City Park архитектура'}, {image: img_4_3, title: 'ЖК City Park архитектура'}, {image: img_5_3, title: 'ЖК City Park архитектура'},];

export const arrObjects = [{image: oil_6, title: 'Москва Сити', text: 'деловой центр',}, {image: oil_1, title: 'Белый дом', text: 'Дом правительства РФ',}, {image: oil_2, title: 'Москоовский зоопаарк', text: 'парк отдыха',}, {image: oil_3, title: 'Красная Пресня', text: 'парк отдыха',}, {image: oil_4, title: 'Красногвардейские пруды', text: 'парк отдыха',}, {image: oil_5, title: 'Экспоцентр', text: 'выставочный комплекс',}, {image: oil_7, title: 'Поклонная гора', text: 'мемориальный парк',}, {image: oil_8, title: 'Центр международной торговли', text: 'бизнес-центр',}, {image: oil_9, title: 'Гостиница Украина', text: 'отель',},];


export const arrDeveloper = [{image: dev_1, title: 'Мосфильмовский', text: 'жилой комплекс',}, {image: dev_2, title: 'Хорошевский', text: 'жилой комплекс',}, {image: dev_3, title: 'Богородский', text: 'жилой комплекс',}, {image: dev_4, title: 'Аист Резиденс', text: 'жилой комплекс',}, {image: dev_5, title: 'Дубровская слобода', text: 'жилой комплекс',}, {image: dev_6, title: 'Just M', text: 'жилой комплекс',},];

