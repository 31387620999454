import React, {useState, useEffect, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Planning.module.scss'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'
import PlanningCard from '../PlanningCard/PlanningCard'
import { arrOneRoom } from '../../utils/data'
import { arrTwoRooms } from '../../utils/data'
import { arrThreeRooms } from '../../utils/data'
import { arrFourRooms } from '../../utils/data'

const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const Planning = ({isPresentationOpen}) => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)
    const [arr, setIsArr] = useState(arrOneRoom)

    const [isImage, setIsImege] = useState(2)
    const [isBackground, setIsBackground] = useState(2)

    useEffect(() => {
        const getCards = () => {
            if (width >= 980) {
                setMoviesTotal(6);
                setAddMovies(3);
                } else if (width < 980 && width >= 650) {
                    setMoviesTotal(4);
                    setAddMovies(2);
                        } else if (width <= 650) {
                            setMoviesTotal(3);
                            setAddMovies(1);
                            }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])

    const handleBackgroundTwo = useCallback(() => {
        setIsBackground(2)
        setIsImege(2)
        setIsArr(arrOneRoom)
    }, []);

    const handleBackgroundThree = useCallback(() => {
        setIsBackground(3)
        setIsImege(3)
        setIsArr(arrTwoRooms)
    }, []);

    const handleBackgroundFour = useCallback(() => {
        setIsBackground(4)
        setIsImege(4)
        setIsArr(arrThreeRooms)
    }, []);

    const handleBackgroundFive = useCallback(() => {
        setIsBackground(5)
        setIsImege(5)
        setIsArr(arrFourRooms)
    }, []);

    return (
        <motion.section 
            className={styles.main}
            id='planirovki'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Планировки и цены</motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>Вы можете выбрать подходящий вариант квартиры в жилом комплексе City Park и записаться на просмотр в нашем разделе с планировками и ценами.</motion.p>
                <motion.ul className={styles.list_link} variants={titleAnimation}>
                        <li className={`${isBackground === 2 ? styles.item_blue : styles.item}`} onClick={handleBackgroundTwo}>1 спальня</li>
                        <li className={`${isBackground === 3 ? styles.item_blue : styles.item}`} onClick={handleBackgroundThree}>2 спальни</li>
                        <li className={`${isBackground === 4 ? styles.item_blue : styles.item}`} onClick={handleBackgroundFour}>3 спальни</li>
                        <li className={`${isBackground === 5 ? styles.item_blue : styles.item}`} onClick={handleBackgroundFive}>4 спальни</li>
                </motion.ul>
                <motion.ul className={`${isImage === 2 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                        {arrOneRoom.map((item, index) => {
                        const keyUid = uuidv4()
                        if (index + 1 <= moviesTotal) {
                            return(
                                <PlanningCard 
                                key={keyUid}
                                apartment={item.apartment}
                                price={item.price}
                                status={item.status}
                                size={item.size}
                                isOpen={isPresentationOpen}
                                image={item.img}
                                />
                            ) 
                        } else {
                            return ''
                        }
                    })}
                </motion.ul>
                <motion.ul className={`${isImage === 3 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                    {arrTwoRooms.map((item, index) => {
                            const keyUid = uuidv4()
                            if (index + 1 <= moviesTotal) {
                                return(
                                    <PlanningCard 
                                    key={keyUid}
                                    apartment={item.apartment}
                                    price={item.price}
                                    status={item.status}
                                    size={item.size}
                                    isOpen={isPresentationOpen}
                                    image={item.img}
                                    />
                                ) 
                            } else {
                                return ''
                            }
                        })}
                </motion.ul>
                <motion.ul className={`${isImage === 4 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                    {arrThreeRooms.map((item, index) => {
                                const keyUid = uuidv4()
                                if (index + 1 <= moviesTotal) {
                                    return(
                                        <PlanningCard 
                                        key={keyUid}
                                        apartment={item.apartment}
                                        price={item.price}
                                        status={item.status}
                                        size={item.size}
                                        isOpen={isPresentationOpen}
                                        image={item.img}
                                        />
                                    ) 
                                } else {
                                    return ''
                                }
                            })}
                </motion.ul>
                <motion.ul className={`${isImage === 5 ? styles.list_image : styles.list_image_none}`} variants={boxAnimation}>
                    {arrFourRooms.map((item, index) => {
                                const keyUid = uuidv4()
                                if (index + 1 <= moviesTotal) {
                                    return(
                                        <PlanningCard 
                                        key={keyUid}
                                        apartment={item.apartment}
                                        price={item.price}
                                        status={item.status}
                                        size={item.size}
                                        isOpen={isPresentationOpen}
                                        image={item.img}
                                        />
                                    ) 
                                } else {
                                    return ''
                                }
                            })}
                </motion.ul>
                {moviesTotal < arr.length && 
                (<div className={styles.box_butoon}>
                    <button className={styles.butoon} type='button' onClick={addCards}>смотреть еще варианты</button>
                </div>)}
                
            </div>
        </motion.section>
    )
}

export default Planning