import React from 'react'
import styles from './PlanningCard.module.scss'

const PlanningCard = ({isOpen, apartment, price, status, size, image}) => {
    console.log(image)

    return (
        <li className={styles.item} onClick={isOpen}>
            {image === undefined ? 
            (<div className={styles.item_info}>
                <h3 className={styles.item_subtitle}>Рисуем планировку,</h3>
                <p className={styles.item_text}>но&nbsp;на&nbsp;просмотр в&nbsp;офис продаж можно пойти уже&nbsp;сейчас.</p>
                <p className={styles.item_text}>Оставьте заявку и&nbsp;мы&nbsp;всё&nbsp;устроим.</p>
                <p className={styles.item_link}>Записаться на просмотр</p>
            </div>) : 
            (<div className={styles.item_info}>
                <img className={styles.item_image} src={image} alt='планировка'/>
            </div>)}
            <div className={styles.item_box}>
                <p className={styles.item_sise}>{status}</p>
                <p className={styles.item_sise}>{size}м²</p>
            </div>
            <h3 className={styles.item_apartment}>{apartment}</h3> 
            <p className={styles.item_price_link}>узнать стоимость</p>
        </li>
    )
}

export default PlanningCard